<template>
  <div class="tops">
    <div class="tops__body">
      <Tops id="Tops" await-for-season :season="numberSeasonId" :is-cup="isCup" :show-logos="false" :custom="custom" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TopsSection',
  components: {
    Tops: () => import('@/views/Tops'),
  },
  data() {
    return {
      custom: {
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        color: '#cbee6b',
        background: '#242424',
        colorheader: '#132839',
        backgroundheader: '#CCE8B5',
        headerBackground: '#132839',
        headerColor: '#CBEE6E',
        headerBorderColor: '#CBEE6E',
        header2Background: '#132839',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cbee6b',
        subHeaderColor: '#353535',
        headerBetToolBackground: '#242424',
        subheaderBetToolBackground: '#151515',
      },
    };
  },
  computed: {
    ...mapState('leagueGeneral', ['objectTournamentSelected', 'objectSeasonSelected']),
    isCup() {
      return this.objectTournamentSelected.id === 33;
    },
    numberSeasonId() {
      return this.objectSeasonSelected.id || 0;
    },
  },
};
</script>

<style scoped lang="scss">
.tops {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__body {
    max-width: 1296px;

    & .back-button {
      width: fit-content;
      padding: 9px 20px;
      margin: 32px 0 32px 8px;
      border-radius: 10px;
      color: white;
      background-color: #132839;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & .image {
        height: 32px;
        width: 32px;
      }

      & .text {
        margin-left: 17px;
      }
    }
  }
}
</style>
